@import '~@uopx/styles/dist/sass/variables';
@import '~@uopx/styles/dist/sass/mixins';
//@import '~@uopx/styles/dist/sass/cssBaseline';
@import '~@uopx/styles/dist/sass/utilities/accessibility';

$color-system-teal: #30718d;
$color-system-teal-d20: #1a3d4d;
$color-system-teal-d40: #16323d;
//secondary theme
$secondary-color: $color-system-teal;
$secondary-hover-color: $color-system-teal-d20;
$secondary-active-color: $color-system-teal-d40;

.uopx-button {
  &.MuiButton-root {
    text-transform: inherit;
    background-color: transparent;
    color: $color-black;
    font-weight: $font-weight-medium;
    border-width: $btn-border-width;
    border-style: $btn-border-style;
    border-color: transparent;
    box-shadow: none;
    width: 100%;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    @media (min-width: $min-md) {
      margin-right: 1rem;
      width: auto;
      margin-bottom: 0;
    }

    &.MuiButtonBase-root {
      border-radius: $btn-border-radius !important;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    &.Mui-focusVisible {
      box-shadow: none;
    }
    // SIZE large
    &.MuiButton-sizeLarge {
      padding: 0.6rem 2rem;
    }
    // SIZE small
    &.MuiButton-sizeSmall {
      padding: 0.3rem 1rem;
    }
    // Primary
    &.MuiButton-containedPrimary {
      background-color: $btn-primary-bg;
      color: $color-white;
      font-size: 1.6rem;
      &:hover {
        background-color: $primary-hover-color;
      }

      &:active {
        background-color: $primary-active-color;
      }

      &.Mui-disabled {
        color: $ada-large-size-gray-on-e3e6e7;
        background-color: $color-light-gray;
      }
    }

    &.itpg-submit {
      background-color: transparent;
      border-color: white;
      border-width: 2px;
      color: $color-white;
      font-size: 1.6rem;
      height: 40px !important;
      min-width: 240px !important;
      margin-bottom: 0 !important;
      &:hover {
        background-color: #1d333d !important;
        outline: none !important;
      }
      &:focus, &:focus-visible {
        background: transparent !important;
        border-color: white !important;
        color: white !important;
        border-width: 3px !important;
      }
      &:active {
        background-color: #3a4f59 !important;
      }
      &.Mui-disabled {
        color: $ada-large-size-gray-on-e3e6e7;
        background-color: $color-light-gray;
      }
    }

    // VARIANT contained - secondary
    &.MuiButton-containedSecondary {
      background-color: $secondary-color;
      color: $color-white;
      font-size: 1.6rem;
      &:hover {
        background-color: $secondary-hover-color;
      }

      &:active {
        background-color: $secondary-active-color;
      }

      &.Mui-disabled {
        color: $ada-large-size-gray-on-e3e6e7;
        background-color: $color-light-gray;
      }
    }
    // VARIANT outlined - Primary
    &.MuiButton-outlinedSizeLarge {
      border-color: $primary-color;
      border-width: $btn-border-width;
      color: $primary-color;

      &:hover {
        border-color: $primary-hover-color;
        background-color: transparent;
        color: $primary-hover-color;
        border-width: $btn-border-width;
      }

      &:active {
        border-color: $primary-active-color;
        background-color: transparent;
        color: $primary-active-color;
      }

      &.Mui-disabled {
        color: $ada-large-size-gray-on-white;
        border-color: $color-light-gray;
      }
    }
    // VARIANT outlined - Secondary
    &.MuiButton-outlinedSecondary {
      border-color: $secondary-color;
      border-width: $btn-border-width;
      color: $secondary-color;

      &:hover {
        border-color: $secondary-hover-color;
        background-color: transparent;
        color: $secondary-hover-color;
        border-width: $btn-border-width;
      }

      &:active {
        border-color: $secondary-active-color;
        background-color: transparent;
        color: $secondary-active-color;
      }

      &.Mui-disabled {
        color: $ada-large-size-gray-on-white;
        border-color: $color-light-gray;
      }
    }
    // VARIANT outlined - Secondary small
    &.MuiButton-outlinedSizeSmall {
      border-color: $primary-color;
      color: $primary-color;

      &:hover {
        border-color: $primary-hover-color;
        background-color: transparent;
        color: $primary-hover-color;
      }

      &:active {
        border-color: $primary-active-color;
        background-color: transparent;
        color: $primary-active-color;
      }

      &.Mui-disabled {
        color: $ada-large-size-gray-on-white;
        border-color: $color-light-gray;
      }
    }
    // Icons
    .MuiButton-label {
      font-size: 1rem;
      align-items: center;
    }
  }
  // VARIANT text - Tertiary
  a.MuiButton-text {
    color: $primary-color;
    font-weight: 400;

    &:hover {
      color: $primary-hover-color;
      background-color: transparent;

      .uopx-tertiary-icon {
        fill: $primary-hover-color;
        margin-left: 0.8rem;
      }
    }

    &:focus {
      .uopx-tertiary-icon {
        fill: $primary-hover-color;
        margin-left: 0.8rem;
      }
    }

    &:active {
      color: $primary-active-color;
      background-color: transparent;

      .uopx-tertiary-icon {
        fill: $primary-active-color;
        margin-left: 0.8rem;
      }
    }

    .uopx-tertiary-icon {
      fill: $primary-color;
      margin-left: 0.4rem;
      transition: margin-left 0.2s ease-in-out;
    }

    &.Mui-disabled {
      color: $ada-large-size-gray-on-white;

      .MuiButton-label {
        color: $ada-large-size-gray-on-white;
      }

      .uopx-tertiary-icon {
        fill: $ada-large-size-gray-on-white;
        margin-left: 0.4rem;
      }
    }
    // SIZE large
    &.MuiButton-textSizeLarge {
      padding: 0.6rem 0;
    }
    // SIZE small
    &.MuiButton-textSizeSmall {
      padding: 0.3rem 0;
    }
  }
}