$input-error-border-color: #B21F13;
$error-text-color: #8A1500;

html {
  font-size: 62.5%;
}

.RFIReactApp {
  font-family: "Roboto", serif;
  //background-color: #fff;
  font-size: 1.4rem;

  .rfi {
    margin: 24px;
  }
  .sr-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
  }
  input[type="checkbox"] {
    background-color: initial;
    cursor: pointer;
    appearance: auto;
    box-sizing: border-box;
    margin: 0;
    padding: initial;
    border: initial;
    fill: #000;
    height: 32px;
    width: 32px;
    vertical-align: middle;
    accent-color: red;
  }
  .separator {
    border: 0;
    border-top: .2rem solid #D2D7D9;
    margin: 2.4rem 0;
    margin-right:2rem;
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #000;
  }

  .uopLogo {
    height: 6rem;
    width: 18rem;
    margin: 8px 0;

    @media (max-width: 900px) {
      height: 6rem;
      width: 13rem;
    }
  }

  .uopLogoTitle {
    display: none;

    @media (max-width: 600px) {
      display: block;
      height: auto;
      font-size: 20px;
      color: #001823;
      line-height: 26px;
      width: 100%;
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }

  .MuiAppBar-root {
    background-color: #fff;
  }

  .main-container {
    margin-top: 15rem;
  }

  .uopx-textarea textarea {
    padding: 0;
  }

  fieldset {
    top: 0 !important;
    padding: 0 !important;
  }

  .MuiInputAdornment-positionEnd {
    margin: 0 !important;
    width: 32px;
    padding: 26px 0;
    border-radius: 4px;
  }

  .footeremail .MuiInputAdornment-positionEnd {
    margin: 0 !important;
    width: 32px;
    padding: 20px 0;
  }

  .MuiSvgIcon-colorSecondary {
    color: #008563 !important;
  }

  .MuiSvgIcon-colorPrimary {
    color: #db3725 !important;
  }

  .MuiInputBase-root {
    font-size: 16px;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    border-radius: 3px;
  }

  .MuiFormControl-root {
    margin-bottom: 24px !important;
    width: 100%;
  }

  #sch-call-formControl {
    margin-bottom: 0px !important;
  }

  .abandonment .MuiFormControl-root,
  .footernewletter .MuiFormControl-root {
    margin-bottom: 16px !important;
  }

  .MuiRadio-root {
    padding: 3px;
  }

  .MuiFormLabel-root {
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    white-space: initial;
    overflow: unset;
  }

  .MuiOutlinedInput-root {
    padding: 0 !important;
  }

  .MuiOutlinedInput-input {
    padding: 22px 14px 14px !important;
    color: #5E7079;
    height: 1em;
  }

  .uopx-input.footeremail.modalfield.MuiTextField-root .MuiInputBase-root {
    height: 45px;
  }

  .uopx-input.footer.MuiTextField-root .MuiInputBase-root {
    height: 40px;
  }

  .uopx-input.footeremail.MuiTextField-root .MuiFormLabel-root,
  .uopx-input.footer.MuiTextField-root .MuiFormLabel-root {
    height: 16px;
    margin-top: -2px;
  }

  .uopx-input.footer.MuiTextField-root .MuiFormLabel-root {
    font-size: 1.4rem;
    margin-top: -5px;
  }

  .uopx-input.footeremail .MuiInputLabel-root.MuiFormLabel-filled {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .footeremail .MuiOutlinedInput-input {
    padding: 18px 14px 8px !important;
    height: 18px;
    min-height: 18px;
    border: unset;
  }

  .footer .MuiOutlinedInput-input {
    padding: 14px 14px 8px !important;
    height: 18px;
    min-height: 18px;
    border: unset;
  }

  .uopx-input.footeremail.MuiTextField-root .MuiInputLabel-outlined.MuiInputLabel-shrink,
  .uopx-input.footer.MuiTextField-root .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 2px) scale(0.8);
    line-height: 16px;
    margin-top: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #5e7079;
  }

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border: 1px solid;
    border-color: $input-error-border-color;
    /*   background-color: white; */
  }

  input:-webkit-autofill,
  input:autofill,
  input:-webkit-autofill:hover,
  input:autofill:focus,
  input:-webkit-autofill:focus,
  input:-internal-autofill-selected,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: #fff !important;
  }

  .MuiSelect-select:focus {
    border-radius: 4px;
    background-color: #fff;
  }

  .PrivateNotchedOutline-root-18 {
    top: 0;
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
  }

  .sticky-footer-container {
    display: none !important;
  }

  .uopx-button.MuiButton-root .MuiButton-label {
    font-size: 16px !important;
    font-weight: 500;
    align-items: center;
    color: #fff;
  }

  .uopx-button.MuiButton-root.MuiButton-outlined .MuiButton-label {
    font-size: 16px !important;
    font-weight: 500;
    align-items: center;
    color: $error-text-color;
  }

  .uopx-button.MuiButton-root.MuiButton-sizeLarge {
    height: 56px;
    min-width: 240px;
    margin-bottom: 8px;
  }

  .reqinfo {
    font-size: 1.4rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    height: 16px;
    letter-spacing: 0.00938em;
    color: #5e7079;
    margin-bottom: 8px;
  }

/*  .MuiButton-root:focus,
  .MuiButton-root:hover,
  .MuiButton-root:active,
  .Mui-focusVisible {
    outline: 2px solid #001823;
    outline-offset: 2px;
    box-shadow: 0 0 0 2px #fff;
  } */

  .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.54);
  }

  h1 {
    height: auto;
    font-size: 48px;
    color: #001823;
    line-height: 54px;
    margin-bottom: 16px;
    margin-top: 0;
  }

  h2 {
    height: auto;
    font-size: 34px;
    color: #001823;
    line-height: 38px;
    font-weight: normal;
    text-align: left;
  }
  h2.title-small { 
    font-size:22px !important;
    line-height:28px !important;
  }

  .mainHeader {
    font-family: Roboto;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .backbtn {
    color: #001823;
    margin-bottom: 24px;
    font-size: 16px;
    text-transform: none;
  }

  .MuiFormGroup-row {
    margin-bottom: 24px;
  }

  .MuiFormControlLabel-label {
    font-family: Roboto, serif;
    font-size: 16px;
    color: #001823;
    text-decoration: none solid rgb(94, 112, 121);
    line-height: 20px;
    height: auto;
    padding-top: 0.5rem;
  }

  .stateConsent {
    height: auto;
    font-family: Roboto, serif;
    font-size: 12px;
    font-weight: bold;
    color: #001823;
  }

  .stateConsent .MuiFormControlLabel-label {
    height: auto !important;
  }

  .MuiSelect-icon {
    top: 10px;
    right: 8px;
  }

  .MuiSvgIcon-root {
    width: 32px !important;
    height: 32px !important;
  }
  .MuiCheckbox-root .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.8);
  }
  
  .MuiCheckbox-root .MuiSvgIcon-root path {
    stroke: #5e7079;
    stroke-width: 0;
    clip-path: inset(8%);
    transform: scale(1.3);
    transform-origin: center;
  }
  
  .inlineFormContainer .MuiCheckbox-root .MuiSvgIcon-root path {
    stroke: #fff;
    stroke-width: 1;
  }

  .MuiFormControl-fGWhite .MuiSvgIcon-root {
    fill: white !important;
  }

  .MuiFormControl-fGWhite .MuiTypography-root {
    color: white !important;
  }

  .MuiFormControl-fGWhite .MuiTypography-body1 {
    font-size: 16px;
  }

  .MuiRadio-colorPrimary.Mui-checked {
    color: #001823;
  }

  .uopx-checkbox {
    width: 35px !important;
    margin-bottom: 0 !important;
  }

  .uopx-checkbox .MuiFormGroup-root {
    width: 35px !important;
  }

  .AlumniChroniclesMagazine-form .uopx-checkbox {
    width: 0 !important;
  }

  .AlumniChroniclesMagazine-form .cbe-checkbox .MuiFormControlLabel-label {
    margin-left: 45px;
  }

  .cbe-checkbox,
  .schedule-checkbox {
    display: inline-flex;
  }

  .disclaimer-checkbox {
    border-bottom: 1px solid #001823;
    padding-bottom: 20px !important;
  }

  .ways2save-checkbox {
    margin-bottom: 24px;
  }
  .ways2save-checkbox label, .cbe-checkbox label, .schedule-checkbox label {
    margin-right: 0;
    -webkit-align-items: start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    vertical-align: top;
  }


  .disclaimer-checkbox .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: bold;
    color: #001823;
    line-height: 18px;
    height: auto;
    margin-left: 30px;
  }

  .disclaimer-checkbox-inline .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: bold;
    color: #001823;
    line-height: 18px;
    height: auto;
    padding-bottom: 20px;
    margin-left: 30px;
  }

  .mail-drip-checkbox .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: normal;
    padding-bottom: 25px;
    margin-left: 0px;
  }

  .mail-drip-checkbox .MuiIconButton-label .MuiSvgIcon-root {
    fill: currentColor;
  }

  .schedule-checkbox .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: normal;
    color: #001823;
    line-height: 24px;
    height: auto;
    padding-bottom: 0;
  }

  .cbe-checkbox .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: normal;
    color: #001823;
    line-height: 24px;
    height: auto;
    padding-bottom: 0; 
  }

  .AlumniChroniclesMagazine-form .cbe-checkbox .MuiFormControlLabel-label {
    font-size: 12px;
    line-height: 18px;
  }

  .cbe-checkbox-inline .MuiFormControlLabel-label {
    font-size: 16px;
    color: #001823;
    line-height: 24px;
    height: auto;
    padding-bottom: 0;
    padding-top: 0;
    margin-left: 30px;
  }

  .schedule-call {
    padding-top: 14px;
  }

  .schedule-call>fieldset>.schedule-checkbox>.uopx-checkbox,
  .cbe>fieldset>.cbe-checkbox>.uopx-checkbox {
    margin-bottom: 0px !important;
  }

  /* .Mui-error .MuiOutlinedInput-notchedOutline {
      border: 1px solid;
      border-radius: 3px 3px 0px 0px;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 2px solid #000;
  } */
  .react-datepicker-popper {
    margin-top: -24px;
  }

  .react-datepicker {
    font-size: 14px;
  }

  .mobile-footer {
    margin: 24px -24px;
    font-size: 12px;
    padding-left: 24px;
    margin-bottom: 0;
  }

  .mobile-footer2 {
    background-color: rgb(242, 243, 243);
    padding: 5px 24px;
    font-size: 12px;
  }

  .mobile-form-businessGuide {
    padding: 24px 16px;
    border-radius: 4px;

    .business-guide-form-title-tablet {
      display: none;
    }
  }

  .mobile-form .radioGroupContainer {
    margin-bottom: 0 !important;
  }

  .emailus-main {
    margin: 24px;
  }

  legend {
    font-size: 16px;
    font-weight: 400;
  }

  .selectRadioLabel {
    font-size: 16px;
    font-weight: 400;
  }

  .selectRadioLabel-semiBold {
    font-size: 16px;
    font-weight: 500;
  }

  .selectRadioLabel-bold {
    font-size: 16px;
    font-weight: 400;
  }

  .selectRadioLabel .MuiFormControlLabel-label {
    font-size: 14px;
    margin-right: 20px;
    margin-left: 5px;
  }

  .selectRadioBoxLabel .MuiFormControlLabel-label {
    font-size: 16px;
    height: 37px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .selectRadioBoxLabel .MuiFormControlLabel-root {
    margin: 0.8rem;
    margin-left: 0;
    margin-right: 0;
    padding: 0.8rem;
    box-shadow: 0px 0px 16px -2px rgb(0 0 0 / 20%);
  }

  .RadioGroupheight {
    height: 30px;
  }

  .disclaimer {
    font-size: 12px;
    margin-bottom: 16px;

  }

  .disclaimer p {
    margin-top: 1rem;
  }

  .legal-text {
    background-color: white;
    color: black;
    padding: 25px;
    width: 100%;
  }

  .inline-rfi-btn {
    width: 100% !important;
  }

  @media (max-width: 640px) {
    .uopx-button.MuiButton-root {
      width: 100%;
    }

    .cbe-checkbox .MuiFormControlLabel-label,
    .schedule-checkbox .MuiFormControlLabel-label {
      margin-left: 0;
    }

    .AlumniChroniclesMagazine-form .cbe-checkbox .MuiFormControlLabel-label {
      margin-left: 45px;
    }

    fieldset {
      border: 0
    }

    .mainHeader {
      font-size: 24px;
    }
  }

  @media (min-width: 640px) {
    .addingmarginright{
      margin-right: 3.2rem;
    }
    .emailfirstname {
      padding-right: 8px;
    }

    .emaillastname {
      padding-left: 8px;
    }

    .alumni-question>.uopx-textarea>.MuiInputBase-multiline {
      min-height: 295px;
    }

    .alumni-question textarea {
      min-height: 250px;
    }

    .alumni-story>.uopx-textarea>.MuiInputBase-multiline {
      min-height: 215px;
    }

    .alumni-story textarea {
      min-height: 170px;
    }

    .reduceHeight>.uopx-textarea>.MuiInputBase-multiline {
      min-height: 100px;
    }

    .reduceHeight textarea {
      min-height: 100px;
    }

    #flex_more_info_instructions {
      padding-top: 5.8rem !important;
      min-height: 9.2rem !important;
      position: relative;
    }

    fieldset {
      display: flex;
    }


    #BgBannerModalSubmit, #BgAbandonmentModalSubmit {
      width: 80%;
    }
  }


  @media (max-width: 639px) {
    .reduceHeight>.uopx-textarea>.MuiInputBase-multiline {
      min-height: 100px !important;
    }

    .reduceHeight textarea {
      min-height: 100px !important;
    }

    #flex_more_info_instructions {
      padding-top: 7.7rem !important;
      min-height: 8.5rem !important;
      position: relative;
    }

    .mobile-form-businessGuide {
      padding: 0;

      .business-guide-form-title-tablet {
        display: none;
      }
    }
  }

  .mobile-submit-btn .uopx-button.MuiButton-root.MuiButton-sizeLarge {
    font-size: 16px;
    /* color:$error-text-color; */
    text-transform: none;
    border-radius: 6px;
    width: 100%;
    height: 46px;
  }

  .mobile-submit-btn .uopx-button.MuiButton-root .MuiButton-label {
    font-size: 20px;
    line-height: 15px;
  }

  .MuiCheckbox-root {
    padding: 0;
    padding-right: .5rem;
  }

  .mobile-state-consent .disclaimer-checkbox .MuiCheckbox-root {
    padding: 0 !important;
    margin-left: 0;
  }

  .mobile-state-consent .disclaimer-checkbox .MuiFormControlLabel-label {
    margin-left: 20px;
  }

  .mobile-backbtn {
    height: 24px;
    font-size: 16px;
    color: #302ff2;
    text-transform: none;
  }

  @media only screen and (min-width: 682px) and (max-width: 990px) {
    .mobile-form-businessGuide {
      .business-guide-form-title-tablet {
        display: block;
      }

      .business-guide-form-title-web {
        display: none;
      }

      .rfiabandonmentcopy {
        padding: 0 0 0 3rem;
      }
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 30px;
      line-height: 34px;
    }

    h2 {
      font-size: 24px;
      line-height: 28px;
    }

    .PrivateSwitchBase-root-14 {
      padding: 5px;
    }

    .disclaimer-checkbox .MuiFormControlLabel-label,
    .mobile-state-consent .disclaimer-checkbox .MuiFormControlLabel-label {
      margin-left: 30px;
    }
  }

  .margin-InlineForm {
    padding: 0 2rem;
    border-radius: 5px;
  }

  .margin-InlineForm.hidden {
    display: none;
  }

  .oneForm-Rectangleline ul {
    padding-inline-start: 20px;
  }

  .continuing-education-side-bar.mobile {
    margin-top: 20px;
  }

  .continuing-education-side-bar span {
    font-size: 20px;
    color: #5e7079;
    text-decoration: none solid rgb(94, 112, 121);
    line-height: 24px;
    display: inline-block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .continuing-education-side-bar.label {
    font-size: 20px;
    color: #5e7079;
    text-decoration: none solid rgb(94, 112, 121);
    line-height: 24px;
    display: inline-block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .continuing-education-side-bar .separator {
    margin-bottom: 10px;
  }

  .continuing-education-side-bar .courses-count {
    font-size: 16px;
  }

  .continuing-education-side-bar .students-trust {
    font-size: 16px;
    color: #001823;
    text-decoration: none solid rgb(0, 24, 35);
    line-height: 24px;
  }

  .continuing-education-side-bar .message {
    margin-top: 10px;
    font-size: 12px;
    color: #5e7079;
    text-decoration: none solid rgb(94, 112, 121);
    line-height: 18px;
  }

  .continuing-education-side-bar .students-count {
    font-size: 32px;
    line-height: 38px;
    color: #001823;
    text-decoration: none solid rgb(0, 24, 35);
    font-weight: 700;
  }

  .continuing-education-container .message {
    display: inline-block;
    margin-top: 15px !important;
  }

  .businessGuideForm-title {
    font-weight: bold;
    font-family: Roboto, serif;
    font-size: 26px;
    color: #001823;
    line-height: 32px;
    margin-bottom: 15px;
  }

  .businessGuideForm-SubTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 15px;
  }


  @media (min-width: 1280px) {
    /*  .newonestep-state-consent span {
      margin-left: -10% !important;
    } */

    .international-student-rfi .internation-form-side-bar {
      margin-top: 0 !important;
    }

    .oneForm-four-grid-step {
      margin-bottom: 10px;
      margin-top: 60px;
      height: 28px;
      font-family: Roboto, serif;
      font-size: 24px;
      color: #001823;
      line-height: 28px;
      font-weight: 700;
    }

    .oneForm-Rectangleline {
      font-family: Roboto, serif;
      font-size: 16px;
      color: #001823;
      line-height: 24px;
    }

    .oneStepForm-li {
      list-style: square;
      margin-top: 24px;
    }

    ul li::marker {
      font-size: 2.2rem;
    }

    .oneStepSolidBlock {
      border-bottom: 4px solid #db3725;
      padding-bottom: 6px;
    }
  }

  .newOneForm-Eyebrow {
    font-family: Roboto, serif;
    font-size: 12px;
    color: $error-text-color;
    line-height: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1.8rem;
  }

  .newOneForm-title {
    font-weight: bold;
    font-family: Roboto, serif;
    font-size: 40px;
    color: #001823;
    line-height: 48px;
    margin-bottom: 15px;
  }

  .newOneForm-SubTitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  @media (max-width: 701px) {
    .newOneForm-title {
      font-weight: bold;
      font-family: Roboto, serif;
      font-size: 24px;
      color: #001823;
      line-height: 28px;
    }

    .businessGuideForm-title {
      font-size: 21px;
      line-height: 32px;
    }
  }

  @media (max-width: 1279px) {
    .oneForm-four-grid-step {
      margin-top: 20px;
      height: 29px;
      font-family: Roboto, serif;
      font-size: 24px;
      color: #001823;
      line-height: 28px;
    }

    .oneForm-Rectangleline {
      font-family: Roboto, serif;
      font-size: 16px;
      color: #001823;
      line-height: 24px;
    }

    .oneStepSolidBlock {
      border-bottom: 4px solid #db3725;
      padding-bottom: 6px;
    }

    .oneStepForm-li {
      list-style: square;
      margin-top: 24px;
    }

    ul li::marker {
      font-size: 2.2rem;
    }
  }

  /*
      @media(max-width: 1280px) and (min-width: 735px){
      .oneForm-Rectangleline {
       margin-bottom: 150px;
       }
      }
      @media(max-width: 734px) and (min-width: 416px){
      .oneForm-Rectangleline {
          margin-bottom: 200px;
       }
       }
       @media(max-width: 416px) {
       .oneForm-Rectangleline {
          margin-bottom: 250px;
        }
      }
  */
  .maxWidthContainer-oneStepForm {
    max-width: 1280px !important;
  }

  .businessGuideInlineFormGrid {
    box-shadow: 0px 0px 16px -2px rgba(0, 24, 35, 0.25);
  }

  .military-filed-oneStep {
    font-family: Roboto, serif;
    font-size: 16px;
    color: #001823;
    line-height: 18px;
  }

  .emp-sub-title {
    font-size: 16px;
    color: #001823;
    margin-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
  }

  .noemployer {
    font-size: 16px;
    color: #001823;
    font-weight: 400;
  }

  .noemployerContainer {
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 10px;
    padding: 2rem;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.15);
  }

  .suggestionContainerParent {
    position: relative;
  }

  .suggestionContainer {
    height: auto;
    width: 100%;
    z-index: 9;
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    margin-top: -24px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.15);
  }

  .suggestionContainerUL {
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 4px;
    padding-left: 0;
  }

  .suggestionContainerLI {
    letter-spacing: 0.00938em;
    font-weight: 400;
    font-size: 16px !important;
    border-color: #000;
    line-height: 1.5;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    white-space: nowrap;
    width: auto;
    overflow: hidden;
    display: block;
    min-height: 40px;
  }

  .employerNoneHeader,
  .employerNoneNumber {
    font-weight: bold;
    margin-top: 0;
  }

  /*  .employer .MuiSvgIcon-root {
    display: none;
  } */

  #menu-fieldOfStudy ul li:nth-of-type(8) {
    display: none;
  }

  #menu-fieldOfStudy ul li:nth-of-type(10) {
    display: none;
  }

  .leftarrow {
    width: 4rem;
    color: #001823;
    padding-right: 5px;
    padding-top: 2px;
  }

  .tertiary__last-word {
    font-size: 16px;
    white-space: nowrap;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    position: relative;
    cursor: pointer;
  }

  .tertiary__last-word:hover {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  .tertiary-word {
    white-space: nowrap;
    padding: 0 0 0 3.1rem;
    font-size:14px;
    line-height: 1.2;
    font-weight: normal !important;
  }

  .tertiary-left-arrow {
    position: absolute;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    bottom: 1.2rem;
  }
 /*  .tertiary-left-arrow:hover {
    transform: translateX(-0.5rem);
    -webkit-transition: transform 0.2s;
    transition: transform 0.2s;
  }

  .tertiary-left-arrow-sch-call {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: solid #db3725;
    border-width: 0 2px 2px 0;
    transform: rotate(135deg);
  }*/

  .cmp-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
  }
  .arrow-container {
      display: flex;
      align-items: center;
      position: relative;
  }

  .arrow-head {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: solid black;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(135deg);
    position: absolute;
    left: 0.5px;
  }

  .arrow-line {
    display: inline-block;
    width: 50px;
    height: 1px;
    background-color: black;
    position: absolute;
    left: 0.1px;
  }
  .sch-call-backbtn {
    padding-top: 24px;
    padding-left: 2.4px;
  }
  @media (max-width:990px) {
    .sch-call-backbtn {
      padding-bottom: 32px;
    }
  }
  .tertiary-word-sch-call {
    white-space: nowrap;
    padding: 0 0 0 5rem;
    text-transform: none !important;
    font-weight: normal !important;
  }

  .tertiary-left-arrow::before {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    border-top: 0.15rem solid #001823;
    border-right: 0.15rem solid #001823;
    top: 50%;
    -webkit-transform: rotate(-135deg) translateY(-65%);
    transform: rotate(-135deg) translateY(-65%);
  }

  .tertiary-left-arrow::after {
    content: "";
    position: absolute;
    width: 3rem;
    height: .5rem;
    border-bottom: 0.15rem solid #001823;
    top: 50%;
    left: -0.5rem;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .activecircle {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 2px 7px;
    background: #db3725;
    border: 0;
    color: #fff;
    text-align: center;
    margin: 5px;
  }

  .circle {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 2px 7px;
    background: #e3e6e7;
    border: 0;
    color: #001823;
    text-align: center;
    margin: 5px;
  }

  .Mui-error:not(label) {
    background-color: #fff;
    font-size: 1.6rem;
    color: $error-text-color !important;
    border-color: $input-error-border-color !important;

    [data-testid="CloseIcon"] {
      display: none;
    }
  }

  .btn-right-arrow {
    background: transparent;
    border: 0;
    color: $error-text-color;
    font-weight: 400;
    padding: 0;
    text-align: left;
    height: auto;
    min-width: 0;
  }

  .right-arrow {
    display: inline-block;
    position: relative;
    margin-left: 4px;
    transition: margin-left 0.3s;
    height: 6px;
    width: 40px;
  }

  .btn-right-arrow:hover .right-arrow {
    margin-left: 12px;
  }

  .right-arrow::before {
    content: "";
    position: absolute;
    width: 3.9rem;
    height: 0;
    border-top: 0.1rem solid;
    border-right: 0.1rem solid;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .btn-right-arrow .right-arrow::after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-top: 0.1rem solid;
    border-right: 0.1rem solid;
    left: 25px;
    right: -3.4rem;
    -webkit-transform:
      rotate(45deg) translateY(-65%);
    transform:
      rotate(45deg) translateY(-65%);
  }

  .MuiFormHelperText-root.Mui-error {
    border-color: $input-error-border-color;
    color: $error-text-color;
    border-style: solid;
    border-width: 1px;
    border-top-width: 0;
    margin: 0;
    padding: 0.4rem 0.8rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: #FBEBEA;
    font-size: 14px;

    &::before {
      content: "!";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      color: #fff;
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
      font-size: 11px;
      margin-right: 5px;
      background-color: $input-error-border-color;
    }
  }

  #formHelperTextWrap {
    font-size: 1.4rem;
    color: $error-text-color;
  }

  .international-student-rfi .stepCounter {
    margin-bottom: 15px;
    font-size: 16px;
    color: #001823;
    text-decoration: none solid rgb(0, 24, 35);
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 700;
  }

  .international-student-rfi .stepCounter div {
    font-weight: 700;
  }

  .international-student-rfi .stepCounter .activeStepCount {
    width: 24px;
    background-color: $error-text-color;
    color: #fff;
    border-radius: 15px;
    display: inline-block;
    text-align: center;
    margin: 0 5px 0 5px;
  }

  .international-student-rfi .stepCounter .totalStepsCount {
    width: 24px;
    background-color: #e3e6e7;
    border-radius: 15px;
    display: inline-block;
    text-align: center;
    margin: 0 5px 0 5px;
  }

  .international-student-rfi .newOneForm-SubTitle {
    font-family: "Roboto", sans-serif;
    color: #001823;
    text-decoration: none solid rgb(0, 24, 35);
    line-height: 24px;
  }

  .international-student-rfi .customLabel {
    font-family: Roboto, serif;
    font-size: 16px;
    color: #001823;
    text-decoration: none solid rgb(0, 24, 35);
    line-height: 18px;
  }

  .international-student-rfi .user-not-eligible-message {
    padding: 10px 24px 22px 24px;
    background-color: #fbebea;
    margin-bottom: 15px;
    font-size: 16px;
    border-left: 4px solid #db3725;
    width: 100%;
  }

  .international-student-rfi .user-not-eligible-message h3 {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .international-student-rfi .not-eligible-btn {
    opacity: 0.4;
    pointer-events: none;
  }

  .international-student-rfi .back-btn {
    width: 100%;
    margin-top: 15px;
  }

  .international-student-rfi .req-info {
    font-size: 14px;
    color: #5e7079;
    text-decoration: none solid rgb(94, 112, 121);
    line-height: 22px;
  }

  /*  .international-student-rfi .mobile-submit-btn .uopx-button {
    height: 56px;
    border-radius: 28px !important;
  } */
  .international-student-rfi .mobile-submit-btn .uopx-button {
    border-radius: 28px !important;
  }

  .international-student-rfi .mobile-backbtn {
    color: #001823;
    margin-top: 15px;
  }

  .international-student-rfi .backButton {
    width: 100%;
  }

  .international-student-rfi button.MuiButton-root:hover,
  .continuing-education-container button.MuiButton-root:hover,
  .mobile-continuing-education-container button.MuiButton-root:hover {
    outline: 2px solid #001823;
    outline-offset: 2px;
    box-shadow: 0 0 0 2px #fff;
  }

  .referral-form {
    color: #fff;
    background: #001823;
    display: flex;
    font-size: 16px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    padding: 3rem;
    flex-direction: column;
  }

  .referral-image {
    text-align: right;
    padding-right: 3rem;
  }

  .btn-text {
    background: transparent;
    color: #fff !important;
    border-bottom: 0.3rem solid #fff;
    font-size: 2rem;
    font-family: "IBM Plex Serif", serif;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #001823;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -65px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #001823 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  @media (min-width: 990px) {
    .refer-a-friend-form .MuiFormControl-root {
      margin-bottom: 30px !important;
    }


    .referral-form {
      margin-top: 3rem;
    }


    .inlineimg {
      background-size: 115% 115%;
      ;
    }
  }

  @media (min-width: 640px) {
    .referral-form {
      color: #fff;
      background: #001823;
      padding: 3rem 8rem;
      font-size: 16px;
      display: flex;
      flex-direction: row;
    }

    .margin-OneStepForm {
      padding-right: 2rem;
    }
  }

  @media (max-width: 440px) {
    .referral-form {
      color: #fff;
      background: #001823;
      padding: 3rem;
      font-size: 16px;
      width: 116.66666%;
      margin-left: -8.3333333%;
      flex-direction: column;
    }
    #LPinlineRFI .legal-text {
      padding:24px !important;
    }
  }
  @media (max-width: 390px) {
    .MuiFormLabel-root, .uopx-input.MuiTextField-root .MuiFormLabel-root {
      font-size: 14px;
    }
  }
  .bgve-inputlabel .MuiFormLabel-root, .bgve-inputlabel .uopx-input.MuiTextField-root .MuiFormLabel-root {
    font-size: 14px;
  }

  li.MuiButtonBase-root {
    display: flex;
    margin-top: 0 !important;
    padding: 6px 16px;
    justify-content: flex-start;
  }

  .thanksContainer {
    margin: auto;
    border: 1px solid;
    text-align: center;
    height: auto;
    min-height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .thanksHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 1rem;
  }

  .thanksHeader img {
    margin-right: 1rem;
  }

/*  input:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-inner-spin-button {
    opacity: 0;
    pointer-events: auto;
    display: none !important;
  } */

  .uopx-input.MuiTextField-root .MuiInputLabel-outlined.MuiInputLabel-shrink,
  .uopx-select .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 4px) scale(0.8);
  }
  
  .uopx-input.uopx-textarea.MuiTextField-root .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 1px) scale(0.8);
    background-color: #fff;
    width: 122%;
  }

  .uopx-native-select>div {
    border: 1px solid #5E7079;
    background-color: #fff;
    margin-top: 0px;

    &:hover {
      border: 1px solid #001823;

      &:after {
        border: solid #001823;
        border-width: 0 2px 2px 0;
      }
    }

  /*  &:focus-within {
      border: 1px solid #001823;
    } */

    &:after {
      content: "";
      border: solid #5E7079;
      border-width: 0 2px 2px 0;
      padding: 4px;
      position: absolute;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      right: 20px;
      pointer-events: none;
    }
  }

  .uopx-native-select>div>select {
    padding: 22px 14px 7px;
  }

  .uopx-native-select>div>svg,
  .MuiSvgIcon-root[data-testid="ArrowDropDownIcon"] {
    top: 10px;
    right: 8px;
    bottom: 14px;
    display: none;

  }

  .MuiNativeSelect-select option {
    color: #001823;
  }

  /*
  .MuiAutocomplete-root > div > div > div{
    &:after {
      content:"";
      border: solid #5E7079;
      border-width: 0 2px 2px 0;
      padding: 4px;
      position:absolute;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      right: 20px;
      pointer-events: none;
    }
  }
  
  .uopx-native-select > label {
    padding:4px 0px 2px; 
    left:3px;
  }
  */
  .date-time-icon {
    padding: 0;
  }

  .disclaimer-checkbox .MuiCheckbox-root {
    padding: 0 !important;
    margin-left: 0;
  }

  .call-break {
    border-bottom: 2px solid #d2d7d9;
    margin: 10px 20px 24px 0;
  }

  .unsub-tab {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 27px;
    text-transform: none;

  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid #B2BBBF;
    height: 55px;
    padding-left: 2px;
    align-items: center;
  }

  .unsub-TabPanel {
    padding: 24px 0px;
  }

  .Mui-selected {
    background-color: #f2f3f3 !important;
    font-weight: 700;
    color: #000 !important;
  }

  .MuiTabs-indicator {
    background-color: #db3725;
    height: 4px;
  }

  button {
    cursor: pointer;
    border: none;
    font-weight: 600;
  }

  .radioBox label {
    box-shadow: 5px 5px 10px #ddd, -2.5px -2.5px 10px #ddd;
    margin-bottom: 16px;
    display: flex;
    height: 5.6rem;
    background-color: #fff;
    border-radius: 5px;

    &:hover {
      background-color: #ddd !important;
    }
  }

  .form {
    margin: 24px !important;
  }

  .uopx-radiogroup .Mui-error.MuiFormHelperText-contained {
    border-color: $input-error-border-color;
    color: $error-text-color;
    border-style: solid;
    border-width: 1px;
    margin: 0;
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    background: #FBEBEA;
  }

/*  .uopx-button.MuiButton-root.MuiButton-sizeSmall:focus,
  .uopx-button.MuiButton-root.MuiButton-sizeSmall:hover,
  .uopx-button.MuiButton-root.MuiButton-sizeSmall:active {
    outline-offset: 2px;
    outline: 2px solid #001823;
    box-shadow: 0 0 0 2px #fff;
  }

  .inline-rfi-btn.uopx-button.MuiButton-root.MuiButton-sizeSmall:focus,
  .inline-rfi-btn.uopx-button.MuiButton-root.MuiButton-sizeSmall:hover,
  .inline-rfi-btn.uopx-button.MuiButton-root.MuiButton-sizeSmall:active {
    background-color: #8a1500;
  } */

  a {
    color: #30718D;
    -webkit-text-decoration-color: #30718D;
    text-decoration-color: #30718D;
  }

  .disclaimer a:hover,
  .disclaimer a:focus, 
  a:hover,
  a:focus {
    color: #db3725;
    -webkit-text-decoration-color: #db3725;
    text-decoration-color: #db3725;
  }

  a.white-disclaimer-link:hover, 
  a.white-disclaimer-link:focus {
    text-decoration-color: #db3725 !important;
  }

  a.white-disclaimer-link {
    color: white !important;
    text-decoration: underline !important;
    font-weight: bold;
  }

/*  .MuiNativeSelect-select:focus,
  .MuiInputAdornment-positionEnd:focus {
    outline-offset: 2px;
    outline: 2px solid #001823;
    border-radius: 5px;
    box-shadow: 0 0 0 2px #fff;
  }

  .inlineFormContainer .MuiNativeSelect-select:focus,
  .inlineFormContainer .MuiInputAdornment-positionEnd:focus,
  .inlineStep1:focus,
  .inlineStep2:focus {
    border-radius: 5px;
    outline: 2px solid #001823;
    outline-offset: 2px;
    box-shadow: 0 0 0 2px #fff ;
  }

  /* .uopx-textarea textarea:focus, .uopx-input input:focus {
    outline-offset: -2px !important;
    outline: 2px solid #001823 !important;
    border-radius: 5px !important;
    box-shadow: unset;
  }  ------

  .Mui-focused .MuiOutlinedInput-notchedOutline,
  .uopx-input.MuiTextField-root .MuiInputBase-root.Mui-focused {
    outline-offset: 2px;
    outline: 2px solid #001823;
    border-radius: 5px;
    box-shadow: 0 0 0 2px #fff;
  }

  .inlineFormContainer .Mui-focused .MuiOutlinedInput-notchedOutline,
  .inlineFormContainer .uopx-input.MuiTextField-root .MuiInputBase-root.Mui-focused {
    outline: 2px solid #001823;
    outline-offset: 2px;
    box-shadow: 0 0 0 2px #fff;
  }
  .MuiInputAdornment-positionEnd:focus {
    outline: 0.2rem solid #001823;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 3px;
  }
*/
  .itGuideFormContainer  .Mui-focused .MuiOutlinedInput-notchedOutline,
  .itGuideFormContainer  .uopx-input.MuiTextField-root .MuiInputBase-root.Mui-focused,
  .itGuideFormContainer .MuiNativeSelect-select:focus {
    outline: 2px solid #fff !important;
    outline-offset: .2rem !important;
    box-shadow: 0 0 0 2px #001823 !important;
  }

  .MuiAutocomplete-listbox {
    border: 1px solid #5E7079;
    font-size: 16px;
    max-height: 380px;
  }

  .MuiAutocomplete-listbox li:nth-child(even) {
    background-color: #f2f3f3;
  }

  .MuiAutocomplete-listbox li:nth-child(odd) {
    background-color: #FFF;
  }
  .redesign-link {line-height: normal;}

}

#timePicker>div>div>div {
  height: 54px !important;
  background-color: #fff !important;
  border: 1px solid #5E7079 !important;
}

.css-1bx7l6n-control {
  height: 54px !important;
  background-color: #fff !important;
  border: 1px solid #5E7079 !important;
}

.css-11v79b0-control {
  border-color: #001823 !important;
  height: 54px !important;
  background-color: #fff !important;
}

.css-hkzqy0-singleValue {
  left: 12px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

.react-datepicker__time-container {
  width: 105px;
}

.react-datepicker__header--time {
  padding: 18px 0;
}

.react-datepicker-time__header {
  font-size: 1.2rem;
}

.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 5px;
}

.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 5px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 104px;
  margin: 0;
}

.MuiCalendarPicker-root .MuiTypography-root,
.MuiCalendarPicker-root .MuiPickersDay-root {
  font-size: 14px;
  font-weight: 700;
}

.MuiTypography-caption,
.MuiTypography-overline {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.MuiSvgIcon-fontSizeMedium {
  width: 2em !important;
  height: 2em !important;
}

.MuiClockPicker-arrowSwitcher {
  top: 0 !important
}

.MuiButton-text,
.MuiTypography-subtitle1 {
  font-size: 14px !important
}

.PrivateDateTimePickerToolbar-penIcon {
  display: none !important
}

.PrivatePickersToolbar-root {
  padding-bottom: 5px !important;
}

.PrivatePickersSlideTransition-root {
  min-height: 200px !important;
}

.duet-date__dialog-content {
  margin: 0;
  max-width: unset;
}

.date-label {
  position: absolute;
  font-size: 1.6rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0.00938em;
  /*    transform: translate(14px, 18px) scale(1); */
}

.duet-date__day,
.duet-date__table-header,
.duet-date__select-label {
  font-size: 1.4rem;
  font-weight: 500;
}

.uopx-wrapper {
  position: relative;
  padding-bottom: 24px;
  font-family: 'Roboto', sans-serif;
}

.uopx-wrapper label {
  position: absolute;
  left: 16px;
  top: 4px;
  z-index: 500;
  font-size: 12px;
  line-height: 16px;
  color: #5e7079;
}

.uopx-wrapper--default label {
  color: #5e7079;
}

.uopx-wrapper--error label {
  color: #DB3724;
}

.uopx-wrapper .duet-date__input {
  height: 56px;
  padding: 24px 60px 16px 16px;
  line-height: 19px;
}

.uopx-wrapper--default .duet-date__input {
  color: #001823;
  border: 1px solid;
  border-color: #5E7079;
  border-radius: 4px;
}

.uopx-wrapper--error .duet-date__input {
  color: #001823;
  border: 1px solid;
  border-color: #DB3724;
  border-radius: 4px 4px 0px 0px;
}

.uopx-wrapper--error .duet-date__input:focus,
.uopx-wrapper--error .duet-date__input:active {
  border-color: #001823;
}

.duet-date__toggle {
  height: calc(100% - 4px);
  right: 2px;
  top: 2px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.uopx-wrapper label.duet-date__mobile-heading {
  position: relative;
  display: inline-block;
  left: 0;
  font-size: 16px;
  line-height: 20px;
  max-width: 95%;
  white-space: normal;
  color: rgba(0, 0, 0, 0.87);
}

@media (min-width: 36em) {
  .duet-date__mobile {
    align-items: flex-start;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0;
    margin-bottom: 15px;
    padding: 0 0 15px 0;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    white-space: normal;
  }

  .duet-date__close {
    opacity: 1;
  }
}

#timeError {
  margin-top: -24px;
}

.MuiPhoneNumber-flagButton {
  margin-left: 15px !important;
  margin-top: 10px !important;
  width: 30px !important;
}

.simpleH3 {
  font-weight: 400;
}

#rfihero {
  background-image: url('https://www.phoenix.edu/content/dam/edu/degrees/img/fos-business-main-desktop.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 490px;
  align-items: center;
  display: flex;
  width: 100%;
  margin-top: -80px;
}

#modalrfi {
  overflow-y: hidden !important;
  display: flex;
  border: 0;
  border-radius: 5px;
  padding: 0;
  //min-height: 500px; 
  position: absolute;
  z-index: 99999999;
  background-color: transparent;
  width: initial;
  height: initial;
  max-width: 900px;
  /*top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

/*.modal .flex {
 display: flex;
 align-items: center;
 justify-content: space-between;
} */
.overlay0P {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 9999999;
}

.hidden, button.nothanks {
  display: none;
}

.fix {
  height: 0;
  opacity: 0;
  width: 0;
}

.btn-close {
  position: absolute;
  right: 2em;
  top: 2.5em;
  padding: 1rem;
  z-index: 9;
  background-size: cover;
  background-color: #fff;
  background-repeat: no-repeat;
  border: 0 solid #eee;
  background-image: url('https://www.phoenix.edu/content/dam/edu/img/icons/global-nav/close-icon-white.png') !important;
}

/* .btn-close:focus,
.btn-close:hover {
  outline-offset: 2px;
  outline: 2px solid black;
} */

.fix {
  height: 0;
  opacity: 0;
}

.fixVE {
  height: 0;
  opacity: 0;
}

.flexVE {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rfiabandonment {
  width: 100%;
  height: auto;
  background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/d-rfi-abanonment.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 395px;
  /*min-width: 325px;*/
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-bottom-left-radius: 4px;
  -moz-border-top-left-radius: 4px;
}

.rfiabandonmentinline {
  width: 100%;
  height: auto;
  background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/d-rfi-abanonment.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 220px;
  /*min-width: 325px;*/
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-bottom-left-radius: 4px;
  -moz-border-top-left-radius: 4px;
}

.rfiabandonmentinline {
  width: 100%;
  height: auto;
  background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/d-rfi-abanonment.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 220px;
  /*min-width: 325px;*/
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-bottom-left-radius: 4px;
  -moz-border-top-left-radius: 4px;
}

.rfiabandonmentcopy {
  padding: 3rem;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  /*align-items: center;
  min-width: 325px;  */
  height: auto;
  background-color: #fff;
}

.tcgParent .rfiabandonmentcopy {
  padding: 32px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  /*align-items: center;
  min-width: 325px;  */
  height: auto;
  background-color: #fff;
}

@media (max-width: 640px) {
  .addingmarginleft{
    margin-right: 0;
    text-align: center;
  }
  .tcgParent .rfiabandonmentcopy {
    padding: 24px;
  }
}

@media (min-width: 641px) and (max-width: 990px) {
  .tcgParent .rfiabandonmentcopy {
    padding: 0 0 0 24px;
  }
}

.modalthankscopy {
  background-color: #fff;
}

.modalVE .modaldesVE {
  font-size: 16px !important;
  line-height: 20px !important;
}

.modalVE .modaltitleVE {
  font-size: 26px !important;
  line-height: 32px !important;
}

.modalimg {
  background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/modalBookletImageDesktop.svg');
  background-position: center;
  background-size: auto;
  background-color: #93CBA4;
}

.modalimg-thanks {
  background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/ScholarshipPiggyBackgroundThankYouDesktopPNG.png');
  background-position: center;
  background-size: auto;
  background-color: #93CBA4;
}

.businessGuide {
  background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/businessGuidedesktop.png');
  background-position: center;
  background-size: auto;
  background-color: #fff;
  padding: 3rem;
}

.businessGuideInline {
  background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/businessGuideInline.png');
  background-position: top;
  background-size: contain;
  background-color: #fff;
  //padding: 3rem;
}

.businessGuideInline {
  background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/businessGuidedesktop.png');
  background-position: top;
  background-size: contain;
  background-color: #fff;
  //padding: 3rem;
}

.transferCreditGuide {
  background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/transferCreditInlineDesktop1440.jpg');
  background-position: center;
  background-size: contain;
  background-color: #fff;
  // padding: 3rem;
}

.schedACallParent {
  //background: var(--Primary-River-Blue-Chromatic-River-Blue-L95, #F0F9FA);
  margin: 0px !important;
  background: #fff;
  //padding: 4rem;
}

.schedACall {
  //background: var(--Primary-River-Blue-Chromatic-River-Blue-L95, #F0F9FA);
  //background: #fff;
  //padding-left: 4rem;
  //padding-right: 4rem;
  padding: 4rem;
}

#standAloneParent .buttons {
  margin-top: 0;
}

#standAloneParent .MuiSvgIcon-root {
  width: 26px !important;
  height: 26px !important;
}

.standAloneStepper > .MuiStepLabel-labelContainer > .MuiStepLabel-label {
  font-size: 14px;
  line-height: 25px;
  color: #001823;
  font-family: Roboto, sans-serif;
  text-transform: none;
}

.standAloneStep:first-of-type{
  padding-left: 0px;
}

.standAloneStep:last-of-type{
  padding-right: 0px;
}

.standAloneStepper > .MuiStepLabel-iconContainer > .MuiStepIcon-root {
  color: #E3E6E7;
  .MuiStepIcon-text {
    fill: #3A4F59;
    font-size: 12px;
  }
}

.standAloneStepper > .MuiStepLabel-iconContainer > .Mui-active {
  color: #30718D;
  .MuiStepIcon-text {
    fill: #fff;
  }
}

.standAloneStepper > .MuiStepLabel-labelContainer > .MuiStepLabel-label.Mui-completed {
  font-weight: 400;
}

.standAloneStepper > .MuiStepLabel-iconContainer > .Mui-completed {
  color: #007558;
}

#standAloneRoot {
  margin: 0px;
}

.schedACall.standAlone {
  padding: 0;
}

@media (max-width: 640px) {
  
  .schedACall {
    padding: 0;
  }

  .schedACallParent {
    padding: 2.4rem 2.4rem 0;
  }

  #standAloneParent {
    padding: 0px;
  }

  .standAloneStep {
    max-width: 75px;
  }

  .MuiStepConnector-horizontal {
    max-width: 10%;
  }

  .standAloneStepper > .MuiStepLabel-labelContainer > .MuiStepLabel-label {
    font-size: 12px;
    line-height: 17px;
  }

  legend.standAlone {
    padding-inline: 0px;
  }
  
  .schedACall .stepCounter.standAlone {
    margin-bottom:24px;
  }

  .maxWidthContainer-oneStepForm.standAlone {
    padding: 16px 24px 32px 24px;
  }

  .newOneForm-SubTitle.standAlone {
    margin-bottom: 16px;
  }

  .newOneForm-SubTitle.standAlone.step3 {
    margin-bottom: 0px;
  }

  .MuiFormControl-root .standAlone > .uopx-input {
    margin-bottom: 32px
  }

  .btn-sg-mb {
    right: 1em;
    top: 1em;
    padding: 1.2rem;
    background-image: url('https://www.phoenix.edu/content/dam/edu/img/icons/global-nav/close-icon-white.png') !important;
  }
}

@media (min-width: 641px) and (max-width: 990px) {

  .maxWidthContainer-oneStepForm.standAlone {
    margin: 24px 40px 32px;
  }

  .buttons .btn-submit.standAlone {
    display: flex;
    justify-self: center;
    width: 56%;
  }

  .schedule-call.standAlone {
    padding-top: 0px;
  }

  .stepCounter.standAlone {
    padding-right: 2rem;
  }

}

@media (max-width: 990px) {

  #standAloneParent .sch-call-backbtn {
    padding-bottom: 40px;
  }

  .mainHeader.standAlone {
    margin-bottom: 16px;
    font-size: 24px;
  }

  h2.standAlone  {
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 24px;
  }

}

@media (min-width: 991px) {

  .schedACall.standAlone {
    justify-content: left;
  }

  .RFIReactApp .schedule-call {
    padding-top: 0px;
  }

  #timePicker {
    padding-bottom: 0px;
  }

  .maxWidthContainer-oneStepForm.standAlone {
    padding: 40px 136px 40px 40px;
    width: 50%;
    align-content: flex-start;
  }

  h2.standAlone  {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 24px;
    padding-right: 2rem;
    line-height: 24px;
  }

  .mainHeader.standAlone {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 40px;
  }

  .stepCounter.standAlone {
    padding-right: 2rem;
  }

}

.tcgParent .btn-close {
  background: rgba(255, 255, 255, 0);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 641px) and (max-width: 990px) {
  .tcgParent .btn-close {
    background: rgba(255, 255, 255, 0);
    background-repeat: no-repeat;
    background-size: cover;
    right: .1em;
    top: .1em;
  }
}

.schedACall fieldset {
  flex-direction: column;
}

.schedACall .stepCounter {
  margin-bottom: 15px;
  font-size: 16px;
  color: #001823;
  text-decoration: none solid rgb(0, 24, 35);
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  // padding-left: 24px;
  //margin-top: 40px;
}

/*.schedACall .mainHeader {
  // padding-left: 24px;
  // margin-bottom: 0px;
} */

.schedACall .thankyoumessage {
  //margin-top: 40px;
  padding-left: 24px;
}

.schedACall .buttons {
//  margin-bottom: 40px;
  margin-top: 8px;
}

.schedACall .stepCounter div {
  font-weight: 700;
  // scroll-margin-top: 120px;
}

.schedACall .stepCounter .activeStepCount {
  width: 24px;
  background-color: #DB3725;
  color: #fff;
  border-radius: 15px;
  display: inline-block;
  text-align: center;
  margin: 0 5px 0 5px;
}

.schedACall .stepCounter .totalStepsCount {
  width: 24px;
  background-color: #e3e6e7;
  border-radius: 15px;
  display: inline-block;
  text-align: center;
  margin: 0 5px 0 5px;
}

.schedACall .customLabel {
  font-family: Roboto, serif;
  font-size: 16px;
  color: #001823;
  text-decoration: none solid rgb(0, 24, 35);
  line-height: 18px;
}

.schedACall .reqinfo {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  margin-bottom: 16px !important;
}

.schedACall .oneStepForm-li {
  list-style: inside !important;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-top: 2px !important;
}

.SGMobImgContainer {
  display: none;
}

.mobile-form .columnContainer {
  flex-direction: row-reverse;
  background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/ScholarshipBladeDesktopBackground.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.columnContainer .rfiabandonment, .columnContainer .rfiabandonmentcopy, .columnContainer .modalthankscopy {
  background-color: transparent;
}
.SGModalOpen #modTitle, #originalTitle, #originalDesc, .SGModalOpen #modDesc, #originalCTA, .SGModalOpen #modCTA {
  display: block;
}
#modTitle, .SGModalOpen #originalTitle, #modDesc, .SGModalOpen #originalDesc, .SGModalOpen .SGMobImgContainer, .SGModalOpen #originalCTA, #modCTA {
  display: none;
}

.scholarshipModal .ways2save-checkbox svg {
  background-color: #fff;
}

.scholarshipModal .ways2save-checkbox svg path {
  transform: scale(1.5) !important;
  transform-origin: center;
}

@media (min-width: 640px) and (max-width: 990px){
  #rfiSGbannercopy, #SGbannerTyForm {
    padding-right: 0;
  }
}

@media (min-width: 990px) {
  .modalimgSGbanner {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/mobileScholarshipBladeImageBook.png');
    background-size: 67% 77%;
    background-position: center;
  }
  .SGModalOpen .modalimg {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/modalBookletImageDesktop.svg');
    background-position: center;
    background-size: auto;
    background-color: #93CBA4;
  }
  .SGModalOpen #SGBannerModalSubmit {
    width: auto;
  }
  #SGBannerModalSubmit {
    width: 33%;
  }
  .SGModalOpen #SGbannerTyForm {
    padding-right: 20px;
  }
  #rfiSGbannercopy, #SGbannerTyForm {
    padding-right: 0;
  }
  #rfiSGbannercopy {
    text-align: left;
  }
  .SGModalOpen #rfiSGbannercopy {
    padding-right: 30px;
    text-align: center;
  }
  .SGModalOpen #thanksdialogtitle-SGbanner, .SGModalOpen #thanksdialogdesc-SGbanner {
    justify-content: center !important;
    text-align: center !important;
  }
  #thanksdialogtitle-SGbanner, #thanksdialogdesc-SGbanner {
    justify-content: flex-start !important;
    text-align: left !important;
  }
  .SGModalOpen .SGbanner-downloadcta {
    padding-left: 30px !important;
  }
  .SGbanner-downloadcta {
    padding-left: 0 !important;
  }
  #modDesc, #originalDesc {
    font-size: 17px;
  }
  .rfiabandonment {
    min-width: 325px;
  }
}

@media (max-width: 990px) {
  .businessGuide {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/businessGuidetablet.jpg');
    background-size: cover;
  }

  .businessGuideInline {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/businessGuideInline.png');
  }

  .businessGuideInline {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/businessGuidetablet.png');
  }

  .transferCreditGuide {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/transferCreditInlineTablet768.jpg');
  }
  #SGMobImg {
    width:100%;
    display:flex; 
    margin:auto;
  }
  .SGMobImgContainer {
    display: flex;
  }
  .mobile-form .columnContainer {
    flex-direction: column-reverse;
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/mobileScholarshipBladeBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  #SGbannerIMG {display: none;}
  .SGModalOpen #SGbannerIMG {display: block;}
  .SGModalOpen #rfiSGbannercopy {
    background-color: #fff;
  }
  #rfiSGbannercopy {
    background-color:#DAF2E1;
  }
}

@media (max-width: 640px) {
  #rfihero {
    height: 325px;
    align-items: flex-end;
  }

  .rfiabandonment {
    min-height: 175px;
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/m-rfi-abanonment.jpg');
    /*  min-width: 325px;*/
  }

  .rfiabandonmentcopy {
    padding: 2rem;
    /*  min-width: 325px; */
  }

  .rfiabandonmentcopy .newOneForm-SubTitle {
    font-size: 14px;
    line-height: 20px;
  }

  .MuiButton-root.MuiButton-Secondary {
    width: 50% !important;
  }

  .abandonment button,
  .unsubscribe button {
    height: initial !important;
  }

  .modalimg {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/ScholarModalImageMobile.svg');
  }

  .modalimg-thanks {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/ScholarshipPiggyBackgroundThankYouMobilePNG.png');
  }

  .modalVE .modaldesVE {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .businessGuide {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/businessGuidemobile.png');
    background-position: center;
    background-size: cover;
  }

  .businessGuideInline {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/businessGuideInline.png');
  }

  .businessGuideInline {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/businessGuidedesktop.png');
  }

  .transferCreditGuide {
    background-image: url('https://www.phoenix.edu/content/dam/edu/request-info/img/transferCreditInlineMobile340x175.jpg');
  }

  #modalrfi {
    width: 85% !important;
    //min-height:unset !important;
  }

  .unsubscribe-image img {
    max-width: -webkit-fill-available;
  }

  #SGMobImg {
    width:70%;
    display:flex; 
    margin:auto;
  }
}

@media (min-width: 640px) {
  .rfiabandonmentcopy .newOneForm-SubTitle {
    font-size: 18px;
  }

  .rfiabandonmentcopy .newOneForm-title {
    font-size: 32px;
    line-height: 38px;
  }

  .rfiabandonmentcopy {
    min-height: 275px;
  }

  .unsubscribe-image img {
    min-width: -webkit-fill-available;
  }

}

.duet-date__input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.duet-date__day.is-month,
.duet-date__day.is-today::before,
.duet-date__day:hover::before {
  border: 2px solid #74AEBF;
  background-color: #FFFFFF;
  box-shadow: none;
}

.duet-date__day.is-outside,
.duet-date__day.is-month.is-disabled {
  border: none;
  background-color: #FFFFFF;
  color: #7B8A92;
}

.duet-date__day:hover {
  border: 2px solid #97C5CF;
  background-color: #E8F9FA;
}

.duet-date__day[aria-pressed=true],
.duet-date__day:focus {
  background-color: #5290A6;
  border: none;
}

.duet-date__dialog.is-active {
  z-index: 9999;
}

.duet-date__day[aria-disabled=true].is-today {
  box-shadow: none !important;
}

@media (max-width: 640px) {
  .duet-date__toggle {
    width: 25%;
  }
}

body[modality=keyboard] .RFIReactApp :focus{
  outline-offset: 2px !important;
}
body[modality="keyboard"] .RFIReactApp input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  box-shadow: unset;
}
body[modality="keyboard"] .RFIReactApp .Mui-focused .MuiOutlinedInput-notchedOutline,
body[modality="keyboard"] .RFIReactApp .MuiOutlinedInput-notchedOutline:focus,
body[modality="keyboard"] .RFIReactApp .uopx-input.MuiTextField-root .MuiInputBase-root.Mui-focused,
body[modality="keyboard"] .RFIReactApp .MuiNativeSelect-select:focus {
    outline-offset: 2px;
    outline: 2px solid #001823;
    border-radius: 5px;
    box-shadow: 0 0 0 2px #fff;
    border:0;
  }

/*.RFIReactApp:not([modality="keyboard"]):focus {
  outline: 0;
  outline-offset: 0;
  box-shadow: unset;
} */

.RFIReactApp:not([modality="keyboard"]) a:focus {
  outline-offset: 2px;
  outline: 2px solid #001823;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #fff;
}
/*.RFIReactApp:not([modality="keyboard"]) a.white-disclaimer-link:focus {
  outline-offset: 2px;
  outline: 2px solid #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #fff;
} */

.unsub-tablist :not([modality="keyboard"]) :focus {
  outline: 0;
}

@media (pointer: coarse) {
  /* mobile device */
}

@media (pointer: fine),
(pointer: none) {
  /* desktop */
}

@media (pointer: fine) and (any-pointer: coarse) {
  /* touch desktop */
}

@media (max-width: 1199px) {
  .unsubscribeEmail {
    margin: 3rem 3rem;
  }

  .unsubscribe-image img {
    content: url("https://www.phoenix.edu//content/dam/edu/request-info/img/emailImageMobile.png");
  }
}

@media (min-width: 1200px) {
  .unsubscribeEmail {
    margin: 30% 10% 0 10%;
  }

  .unsubscribe-image img {
    content: url("https://www.phoenix.edu//content/dam/edu/request-info/img/emailImageDesktop.png");
  }

  .unsubscribeColoum {
    flex-direction: row-reverse !important;
  }
}

#inlineBGImg {
  width: 100%;
}
 .HLE {
  display: none;
} 
.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0,0,0,0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
